/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:e3aa7601-b1e0-4e90-8dd2-d1536ecb6b0b",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_YlslQFrl1",
    "aws_user_pools_web_client_id": "4jfc0j71i67fjsmb7vu5914a49",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "vehicleChecksSubmissionsAPI",
            "endpoint": "https://72uz49ufz2.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
